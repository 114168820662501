import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Box } from "@mui/material";
import * as React from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const YearSelect = (props) => {
  const currentYear = new Date().getFullYear().toString();
  
  const [year, setYear] = React.useState(currentYear);
  const handleChange = (event) => {
    setYear(event.target.value);
  };
  const startYear = 2024; // Start year for the range
  const years = Array.from({ length: currentYear - startYear + 1 }, (_, i) => startYear + i);

  React.useEffect(() => {
    props.yearUpdate(year);
  }, [year]);
  return (
    <Box
      sx={{
        minWidth: 120,
        backgroundColor: "#79DCFF",
        borderRadius: "5px",
        height: "10%",
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" sx={{ color: "#000000" }}>
          Year
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={year}
          sx={{ color: "#000000" }}
          label="Year"
          onChange={handleChange}
        >
        {years.map((year) => (
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
        ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default YearSelect;
